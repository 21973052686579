import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Container, makeStyles, Typography, Grid, Box } from "@material-ui/core"

import BgImage from "../../static/images/aboutBg.svg"
import Logo from "../../static/images/logo/logo_signup.svg"
import StoreIcons1 from "../../static/images/Icons/store-icon.svg"
import StoreIcons2 from "../../static/images/Icons/store-icon-2.svg"

// const windowGlobal = typeof window !== "undefined" && window

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  pageTitleImageBorder: {
    border: "2px solid #000",
    borderRadius: "10px",
  },
  pageTitleText: {
    fontWeight: "600",
    color: "#003e96",
    textAlign: "center",
    fontSize: "1.8rem",
    letterSpacing: ".6px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "34px",
    },
  },
  pageTitleImage: {
    maxWidth: 150,
    minWidth: 100,
    margin: "0rem 0 1rem",
    alignItems: "flex-end",
  },
  pageTitleLogo: {
    maxWidth: 120,
  },
  PageTitleMainGrid: {
    alignItems: "center",
    position: "relative",
    zIndex: "9",
    paddingTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      textAlign: "center",
    },
  },
  pageTitleBg: {
    position: "relative",
    backgroundColor: "#e9e9e9",
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    "&:after": {
      content: "''",
      backgroundImage: `url(${BgImage})`,
      backgroundSize: "cover",
      backgroundPosition: "150px 0%",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      zIndex: "2",
    },
  },
  pageTitleLogoGrid: {
    textAlign: "right",
  },
  pageTitleBrandText: {
    textAlign: "center",
    fontSize: 12,
    color: "#003e96",
  },
  pageTitleBrandText2: {
    textAlign: "center",
    fontSize: 12,
    color: "#447dce",
  },
  PageTitleTextGrid: {
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: "initial",
      marginBottom: "2rem",
    },
  },
  PageTitleBrandGrid: {
    marginTop: 42,
    alignItems: "center",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  storeIcons1: {
    "& img": {
      height: 32,
      // marginTop: 1,
      marginRight: 6,
    },
  },
  storeIcons2: {
    "& img": {
      height: 32,
    },
  },
  pageTitleStoreIcons: {
    marginTop: 8,
  },
  PageTitleBrandTextGrid: {
    paddingRight: 10,
  },
  pageSubTitleText: {
    fontWeight: "600",
    color: "#003e96",
    textAlign: "center",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "34px",
    },
  },
}))

const PageTitle = ({ title, image, border, subTitle }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [borderStyle, setborderStyle] = useState(border)

  useEffect(() => {
    setborderStyle(border)
  }, [border])

  return (
    <Container className={`${classes.fullWidth} ${classes.pageTitleBg}`}>
      <Container>
        <Grid container className={classes.PageTitleMainGrid}>
          <Grid item md={3} sm={12} xs={12}>
            {image && (
              <img
                src={image}
                alt="Page Title"
                className={`${classes.pageTitleImage} ${
                  borderStyle && classes.pageTitleImageBorder
                }`}
              />
            )}
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            className={classes.PageTitleTextGrid}
          >
            <Typography
              variant="h3"
              component="h1"
              className={classes.pageTitleText}
            >
              {title}
            </Typography>
            <Typography
              variant="h4"
              component="h1"
              className={classes.pageSubTitleText}
            >
              {subTitle && subTitle}
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            sm={false}
            xs={false}
            container
            className={classes.PageTitleBrandGrid}
          >
            <Grid item xs={8} className={classes.PageTitleBrandTextGrid}>
              <Typography
                variant="subtitle2"
                component="p"
                className={classes.pageTitleBrandText}
              >
                {t("home.heroText1")}
              </Typography>
              <Typography
                variant="subtitle2"
                component="p"
                className={classes.pageTitleBrandText2}
              >
                {t("home.heroText2")}
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                className={classes.pageTitleStoreIcons}
              >
                <Box className={classes.storeIcons1}>
                  <a href="https://play.google.com/store/apps/details?id=com.arafas.kitabhadef">
                    <img src={StoreIcons1} alt="Google Play" />
                  </a>
                </Box>
                <Box className={classes.storeIcons2}>
                  <a href="https://apps.apple.com/us/app/كتابي-الهادف/id1565685455">
                    <img src={StoreIcons2} alt="App Store" />
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} className={classes.pageTitleLogoGrid}>
              <img
                src={Logo}
                alt="Page Title Logo"
                className={classes.pageTitleLogo}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
export default PageTitle
