import React from "react"
import { Link } from "gatsby"
// import { useTranslation } from "react-i18next"

import { makeStyles, Container, Typography } from "@material-ui/core"

import HomeIcon from "@material-ui/icons/Home"

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  breadcrumbsBg: {
    backgroundColor: "#f4f4f4",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16) !important",
    position: "relative",
    zIndex: "9",
    height: "42px",
  },
  breadcrumbContainerInner: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  breadcrumbMenu: {
    color: "#707070",
    textDecoration: "none",
    verticalAlign: "middle",
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
      padding: 14,
      lineHeight: "1.66rem",
      "&:after": {
        content: "''",
        maxHeight: 42,
        maxWidth: "100%",
        padding: "17px 22px 9px 15px",
        boxShadow: "4px 4px 9px -6px rgba(0, 0, 0, 0.16)",
        borderBottomRightRadius: 31,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
      padding: "14px 0px 14px 8px",
    },
  },
  breadcrumbMenuLinks: {
    color: "#018fff",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      "&:not(:first-child):after": {
        content: "'/'",
        marginLeft: 6,
      },
    },
  },
}))

function Breadcrumbs({ links, isVisible }) {
  const classes = useStyles()

  return (
    <>
      {/* {isVisible && ( */}
        <Container className={`${classes.fullWidth} ${classes.breadcrumbsBg}`}>
          <Container className={classes.breadcrumbContainerInner}>
            <Link
              // ${i18n.language}
              to={`/`}
              className={`${classes.breadcrumbMenu} ${classes.breadcrumbMenuBtnsHover} ${classes.breadcrumbMenuLinks}`}
            >
              <HomeIcon />
            </Link>
            {links.map(item =>
              item.text && item.link ? (
                <Link
                  key={item.link}
                  to={`/${item.link}`}
                  className={`${classes.breadcrumbMenu} ${classes.breadcrumbMenuBtnsHover} ${classes.breadcrumbMenuLinks}`}
                >
                  {item.text}
                </Link>
              ) : (
                item.text && (
                  <Typography
                    key={item.text}
                    component="span"
                    className={classes.breadcrumbMenu}
                  >
                    {item.text}
                  </Typography>
                )
              )
            )}
          </Container>
        </Container>
      {/* )} */}
    </>
  )
}

export default Breadcrumbs
